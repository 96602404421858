import { lazy } from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
const App = lazy(() => import('./pages/App'));
const Page404 = lazy(() => import('./pages/Page404'));
export const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <Outlet />
            </QueryParamProvider>
        ),
        children: [
            {
                path: '/',
                element: <App />
            }
        ]
    },

    {
        path: '*',
        element: <Page404 />
    }
]);
