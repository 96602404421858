console.log(`                                      *(#(*                                     
                          /###########################*                         
                     %#####################################%                    
                 ##############################################%                
              #####################################################             
           ##########################################################/          
         ##############################################################%        
       ##################################################################/      
      #####################################################################     
    *###############                                         ###############.   
   ################                                         #################(  
  *#################.                                    #####################. 
  ###################%                                ######################### 
 ######################                            #############################
 #######################              *###      ,######. #######################
 ########################,         (########  #######   ########################
*##########################     ##################    (#########################
 ########################### ########     #####      ###########################
 #################################                  ############################
 ##############################                    #############################
  ##############################                 /############################# 
  ,##############################               ##############################. 
   ###############################             ##############################*  
    ,##############################,          ##############################    
      ###############################       /##############################     
       (##############################     ##############################,      
         ###############################################################        
           (#########################################################.          
              #####################################################             
                 %#############################################%                
                     %#####################################%                    
                          .###########################                          `);

// Load Sentry for error tracking
import './lib/instrument.ts';

import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { router } from './routes.tsx';

import './index.css';
// Load i18n for translation
import './lib/i18n.ts';

import { RouterProvider } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import constants from './constants.ts';
import Loading from './pages/Loading.tsx';
const queryClient = new QueryClient();

import { Buffer } from 'buffer';


window.Buffer = Buffer;

import { AnimatePresence } from 'framer-motion';
const theme = localStorage.getItem('theme') || 'dark';
ReactDOM.createRoot(document.getElementById('root')!).render(
    // <React.StrictMode>
    <Suspense fallback={<Loading />}>
        <AnimatePresence>
            <QueryClientProvider client={queryClient}>
                <TonConnectUIProvider
                    manifestUrl={constants.tonConnectManifest}
                >
                    <NextUIProvider>
                        <main
                            className={`main ${theme} overflow-x-hidden  bg-background text-foreground`}
                        >
                            <RouterProvider router={router} />
                        </main>
                    </NextUIProvider>
                </TonConnectUIProvider>
            </QueryClientProvider>
        </AnimatePresence>
    </Suspense>
    // </React.StrictMode>
);
