import { create } from 'zustand';

type ITheme = {
    theme: 'light' | 'dark';
    changeTheme: (theme: 'light' | 'dark') => void;
};

export const useTheme = create<ITheme>()((set) => ({
    theme: (localStorage.getItem('theme') as 'dark' | 'light') || 'dark',
    changeTheme: (theme) => {
        const mainElement = document
            .querySelector<HTMLElement>('main.main')
        if (theme === "dark") {
            mainElement!.classList.remove("light");
            mainElement!.classList.add("dark");
        } else {
            mainElement!.classList.remove("dark");
            mainElement!.classList.add("light");
        }
        localStorage.setItem('theme', theme);
        set(() => ({ theme }));
    }
}));
